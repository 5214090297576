<template>
  <div id="app">
    <div class="background-container">
      <div class="background">
        <img src="/bg_2.png" alt="background">
      </div>
    </div>
    <div class="container">
      <img class="banner" src="/bg_font.png" alt="banner">
      <div class="line"></div>
      <ButtonGroup :config="config" @openPopup="openPopup" />
      <div class="line"></div>
      <Popup :domains="domains" v-if="showPopup" @closePopup="closePopup" />
    </div>
    <footer>
      COPYRIGHT © 千诚互娱 版权所有
    </footer>
  </div>
</template>

<script>
import ButtonGroup from './components/ButtonGroup.vue';
import Popup from './components/Popup.vue';

export default {
  components: {
    ButtonGroup,
    Popup
  },
  data() {
    return {
      showPopup: false,
      config: {},
      domains: []
    };
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    async fetchConfig() {
      try {
        const response = await fetch('/config.json?t=${new Date().getTime()}');
        const config = await response.json();
        this.config = config;
        const domainNames = config.VUE_APP_DOMAINS.split(',');
        this.domains = domainNames.map(name => ({ name, latency: '检测中' }));
        console.log(JSON.stringify(this.domains),'xxonffd')
      } catch (error) {
        console.error('Failed to load configuration:', error);
      }
    }
  },
  mounted() {
    this.fetchConfig();
  }
};
</script>

<style>
/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #app {
  height: 100%;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background-color: #EDEDED;
  font-family: Arial, sans-serif;
}

.background-container {
  display: flex;
  justify-content: center;
}

.background {
  max-width: 480px;
  width: 100%;
}

.background img {
  width: 100%;
  height: auto;
  display: block;
}

.container {
  position: relative;
  max-width: 480px; /* Adjust based on your mobile design width */
  margin: 0 auto;
  padding: 0 20px; /* Add some padding to the container */
}

.banner {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -120px;
}

.line {
  height: 1px;
  width: 100%;
  background: url('/line_1.png') repeat-x center;
  margin: 10px auto;
}

footer {
  color: #AFAFAF;
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  flex-shrink: 0;
}

/* Media query for larger screens */
@media (min-width: 600px) {
  .container {
    max-width: 480px; /* Ensure container is centered and does not exceed mobile design width */
  }
}
</style>
